$font_stack_1: 'Rubik', sans-serif;
$font_stack_2: 'Poppins', sans-serif;
/*Color Variables*/

$primary-color  : #6a6565;
$title-color    : #2a2a2a;
$text-color     : #6a6565;
$offwhite       : #f6f6f6;
$white: #fff;
$section_bg: #fcefe3;
$white_color: #fff;
$black_color: #000;
$menu_color: #000;
$icon_color: #0c2e60;
$icon_bg: #f0f4f6;
$author_text_color: #556172;
$section_bg: #fcefe3;
$color_1: #f8fbff;
$color_2: #fffbf9;
$color_3: #fafafa;
$color_4: #fffcf4;
$btn_bg: #6a6565;
$primary_color: #6a6565;
$btn_hover: #f8ece1;
$section_bg_1: #f1d0b3;
$heading_color: #f1d0b3;
$heading_color2: #ff8b23;
$font_1: #666666;
$font_2: #222222;
$font_3: #7f7f7f;
$font_4: #8a8a8a;
$font_5: #999999;
$font_6: #666666;
$font_7: #777777;
$font_8: #888888;
$font_d: #dddddd;
$border_color: #fcefe3;
$footer_bg: #303030;
$sidebar_bg: #fcf0e6;



$medium_device : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$tab_device:'only screen and (min-width: 768px) and (max-width: 991px)';
$large_mobile: 'only screen and (min-width: 576px) and (max-width: 767px)';
$tab:'(max-width: 991px)';
$small_mobile:'(max-width: 576px)';
$xs_mobile:'(max-width: 420px)';
$sm_mobile:'only screen and (min-width: 421px) and (max-width: 575px)';
$big_screen:'only screen and (min-width: 1200px) and (max-width: 1440px)';
$extra_big_screen: 'only screen and (min-width: 1200px) and (max-width: 3640px)';
  
  
  