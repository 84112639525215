/************ about_us css start ***************/
.about_us{
  .about_us_video{
    position: relative;
  }
  .about_us_content{
    h5{
      font-size: 20px;
      color: $btn_bg;
      font-weight: 400;
      @media #{$small_mobile}{
        font-size: 18px;
      }
    }
    h3{
      font-size: 24px;
      line-height: 1.5;
      color: $heading_color;
      margin: 5px 0 60px;
      font-weight: 400;
      @media #{$tab}{
        margin: 5px 0 30px;
        font-size: 20px;
      }
    }
  }
  .about_video_icon{
    height: 84px;
    width: 84px;
    line-height: 84px;
    border-radius: 50%;
    background-color: #6a6565;
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 41%;
    @media #{$small_mobile}{
      height: 50px;
      width: 50px;
      line-height: 50px;
    }
    &:after{
      position: absolute;
      content: "";
      width: 14px;
      height: 18px;
      background-color: $white;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 41%;
      clip-path: polygon(100% 50%, 0 0, 0 100%);
      @media #{$small_mobile}{
        top: 32%;
      }
    }
  }
}
/************ about_us css end ***************/
